const API_URL = process.env.VUE_APP_API_URL;
const API_VERSION = process.env.VUE_APP_API_VERSION;
const URL_AWS = process.env.VUE_APP_URL_AWS;

const API_ROUTES = {
  auth: {
    login: `${API_URL}/api/${API_VERSION}/auth/login`,
    register: `${API_URL}/api/${API_VERSION}/auth/register`,
    recovery: `${API_URL}/api/${API_VERSION}/auth/recovery-password`,
    change: `${API_URL}/api/${API_VERSION}/auth/change-password`,
    changeFirst: `${API_URL}/api/${API_VERSION}/auth/change-password-first-session`,
  },
  profile: {
    get: `${API_URL}/api/${API_VERSION}/profile`,
  },
  planEnterprisePrice: {
    get: `${API_URL}/api/${API_VERSION}/plan-enterprise-price`,
  },
  planEnterprisePriceByType: {
    get: `${API_URL}/api/${API_VERSION}/plan-enterprise-price/by-type`,
  },
  employee: {
    get: `${API_URL}/api/${API_VERSION}/employee/employees-enterprises`,
    find: `${API_URL}/api/${API_VERSION}/employee/employee-user`,
    save: `${API_URL}/api/${API_VERSION}/employee`,
    update: `${API_URL}/api/${API_VERSION}/employee`,
    delete: `${API_URL}/api/${API_VERSION}/employee`,
  },
  enterprise: {
    get: `${API_URL}/api/${API_VERSION}/enterprise`,
    find: `${API_URL}/api/${API_VERSION}/enterprise`,
    save: `${API_URL}/api/${API_VERSION}/enterprise`,
    update: `${API_URL}/api/${API_VERSION}/enterprise`,
    delete: `${API_URL}/api/${API_VERSION}/enterprise`,
  },
  historyPlan: {
    get: `${API_URL}/api/${API_VERSION}/history-plan/history-plan-enterprise`,
    find: `${API_URL}/api/${API_VERSION}/history-plan`,
    save: `${API_URL}/api/${API_VERSION}/history-plan`,
    update: `${API_URL}/api/${API_VERSION}/history-plan`,
    delete: `${API_URL}/api/${API_VERSION}/history-plan`,
  },
  myEnterprise: {
    find: `${API_URL}/api/${API_VERSION}/my-enterprise`,
    update: `${API_URL}/api/${API_VERSION}/my-enterprise`,
  },
  enterpriseAdmin: {
    get: `${API_URL}/api/${API_VERSION}/enterprise/enterprise-admin`,
  },
  owner: {
    get: `${API_URL}/api/${API_VERSION}/owner`,
    find: `${API_URL}/api/${API_VERSION}/owner/owner-user`,
    save: `${API_URL}/api/${API_VERSION}/owner`,
    update: `${API_URL}/api/${API_VERSION}/owner`,
    delete: `${API_URL}/api/${API_VERSION}/owner`,
  },
  transportation: {
    get: `${API_URL}/api/${API_VERSION}/transportation/transportation-enterprise`,
    save: `${API_URL}/api/${API_VERSION}/transportation`,
    update: `${API_URL}/api/${API_VERSION}/transportation`,
    delete: `${API_URL}/api/${API_VERSION}/transportation`,
  },
  row: {
    get: `${API_URL}/api/${API_VERSION}/row/row-transportation`,
    save: `${API_URL}/api/${API_VERSION}/row`,
    update: `${API_URL}/api/${API_VERSION}/row`,
    delete: `${API_URL}/api/${API_VERSION}/row`,
  },
  chair: {
    delete: `${API_URL}/api/${API_VERSION}/chair`,
  },
  travel: {
    get: `${API_URL}/api/${API_VERSION}/travel/travel-enterprise`,
    find: `${API_URL}/api/${API_VERSION}/travel`,
    save: `${API_URL}/api/${API_VERSION}/travel`,
    update: `${API_URL}/api/${API_VERSION}/travel`,
    delete: `${API_URL}/api/${API_VERSION}/travel`,
    date: `${API_URL}/api/${API_VERSION}/travel/travel-enterprise-date`,
  },
  travelByDate: {
    get: `${API_URL}/api/${API_VERSION}/travel/travel-enterprise-by-date`,
  },
  planClient: {
    get: `${API_URL}/api/${API_VERSION}/plan-client/plant-client-enterprise`,
    save: `${API_URL}/api/${API_VERSION}/plan-client`,
    update: `${API_URL}/api/${API_VERSION}/plan-client`,
    delete: `${API_URL}/api/${API_VERSION}/plan-client`,
  },
  planClientByOtherEnterprise: {
    get: `${API_URL}/api/${API_VERSION}/plan-client/plant-client-by-other-enterprise`,
  },
  sale: {
    get: `${API_URL}/api/${API_VERSION}/sale/sale-enterprise-travel`,
    save: `${API_URL}/api/${API_VERSION}/sale`,
    update: `${API_URL}/api/${API_VERSION}/sale`,
    delete: `${API_URL}/api/${API_VERSION}/sale`,
    report: `${API_URL}/api/${API_VERSION}/sale/generate-report-pdf`,
    reportPilot: `${API_URL}/api/${API_VERSION}/sale/generate-report-pdf-pilot`,
  },
  moveSale: {
    update: `${API_URL}/api/${API_VERSION}/sale/move-sale`,
  },
  moveSaleEnterprise: {
    update: `${API_URL}/api/${API_VERSION}/sale/move-sale-enterprise`,
  },
  moveSaleEnterpriseNotExist: {
    update: `${API_URL}/api/${API_VERSION}/sale/move-sale-enterprise-not-exist`,
  },
  payment: {
    get: `${API_URL}/api/${API_VERSION}/payment/payment-sale`,
    save: `${API_URL}/api/${API_VERSION}/payment`,
    update: `${API_URL}/api/${API_VERSION}/payment`,
    delete: `${API_URL}/api/${API_VERSION}/payment`,
  },
  accompanist: {
    get: `${API_URL}/api/${API_VERSION}/accompanist/client`,
    save: `${API_URL}/api/${API_VERSION}/accompanist`,
    update: `${API_URL}/api/${API_VERSION}/accompanist`,
    delete: `${API_URL}/api/${API_VERSION}/accompanist`,
    search: `${API_URL}/api/${API_VERSION}/accompanist/search-by-identification`,
  },
  image: {
    save: `${API_URL}/api/${API_VERSION}/my-enterprise/upload-image`,
    get: 'https://s3-bucket-guiia-tour.s3.amazonaws.com/',
  },
  report: {
    get: `${API_URL}/report/`,
  },
  user: {
    find: `${API_URL}/api/${API_VERSION}/user`,
  },
  logout: {
    save: `${API_URL}/api/${API_VERSION}/auth/logout`,
  },
  saleByDate: {
    get: `${API_URL}/api/${API_VERSION}/sale/sale-travel`,
  },
  typeTransportation: {
    get: `${API_URL}/api/${API_VERSION}/type-transportation`,
  },
  chairSale: {
    update: `${API_URL}/api/${API_VERSION}/chair-sale`,
    delete: `${API_URL}/api/${API_VERSION}/chair-sale/delete-chair-sale`,
  },
  baby: {
    get: `${API_URL}/api/${API_VERSION}/baby/baby-accompanist`,
    save: `${API_URL}/api/${API_VERSION}/baby`,
    update: `${API_URL}/api/${API_VERSION}/baby`,
    delete: `${API_URL}/api/${API_VERSION}/baby`,
  },
  client: {
    get: `${API_URL}/api/${API_VERSION}/client/search-client`,
    update: `${API_URL}/api/${API_VERSION}/client`,
  },
  registerAccompanist: {
    save: `${API_URL}/api/${API_VERSION}/accompanist/register-accompanist-baby`,
    get: `${API_URL}/api/${API_VERSION}/accompanist/buscar-client-sales`,
  },
  liquidation: {
    get: `${API_URL}/api/${API_VERSION}/liquidation/liquidation-enterprise-by-date`,
    travel: `${API_URL}/api/${API_VERSION}/liquidation/liquidation-by-travel`,
    find: `${API_URL}/api/${API_VERSION}/liquidation`,
    save: `${API_URL}/api/${API_VERSION}/liquidation`,
    update: `${API_URL}/api/${API_VERSION}/liquidation`,
    delete: `${API_URL}/api/${API_VERSION}/liquidation`,
  },
  variableLiquidation: {
    get: `${API_URL}/api/${API_VERSION}/variable-liquidation/variable-liquidation-enterprise`,
    find: `${API_URL}/api/${API_VERSION}/variable-liquidation`,
    save: `${API_URL}/api/${API_VERSION}/variable-liquidation`,
    update: `${API_URL}/api/${API_VERSION}/variable-liquidation`,
    delete: `${API_URL}/api/${API_VERSION}/variable-liquidation`,
  },
  geography: {
    department: `${API_URL}/api/${API_VERSION}/geography/departments`,
    municipality: `${API_URL}/api/${API_VERSION}/geography/municipalities`,
  },
  module: {
    get: `${API_URL}/api/${API_VERSION}/module`,
  },
  tutorial: {
    get: `${API_URL}/api/${API_VERSION}/tutorial`,
    find: `${API_URL}/api/${API_VERSION}/tutorial`,
    save: `${API_URL}/api/${API_VERSION}/tutorial`,
    update: `${API_URL}/api/${API_VERSION}/tutorial`,
    delete: `${API_URL}/api/${API_VERSION}/tutorial`,
  },
  tutorialModule: {
    get: `${API_URL}/api/${API_VERSION}/tutorial/tutotial-by-module`,
  }
}

export { API_ROUTES }